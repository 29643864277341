<template>
  <div v-if="showmodal" class="fixed flex justify-center items-center bg-overlay z-999 top-0 left-0 w-full h-full">
    <div class="overflow-y mx-3">
        <div :class="width" class="bg-white rounded px-4 py-4 lg:w-500 w-full ">
            <div v-if="show" >
                <slot></slot>
            </div>
            <div v-else >
                <div class="flex justify-between items-center top-0 bg-white py-2">
                    <p class="text-2xl font-semibold">Select delivery date and timebelt</p>
                    <div class="" @click="$router.go(-1)" >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </div>
                </div> 
                <div>
                    <form @submit.prevent="sortMenu" id="sort" class=" block">   
                        <div class="mt-4">                       
                            <label class="block" >Set delivery date</label>
                            <datepicker  class="input "
                                v-model="todayDate"
                                @update:modelValue="changeAvailableTimebelt"
                                :lowerLimit="lowerDate"
                                :upperLimit="boundaryDate"
                                :style='{
                                    "--vdp-bg-color": "#ffffff",
                                    "--vdp-text-color": "#000000",
                                    "--vdp-box-shadow": "0 4px 10px 0 rgba(128, 144, 160, 0.1), 0 0 1px 0 rgba(128, 144, 160, 0.81)",
                                    "--vdp-border-radius": "3px",
                                    "--vdp-heading-size": "2.5em",
                                    "--vdp-heading-weight": "bold",
                                    "--vdp-heading-hover-color": "#eeeeee",
                                    "--vdp-arrow-color": "currentColor",
                                    "--vdp-elem-color": "currentColor",
                                    "--vdp-disabled-color": "#d5d9e0",
                                    "--vdp-hover-color": "#ffffff",
                                    "--vdp-hover-bg-color": "#c0d800",
                                    "--vdp-selected-color": "#ffffff",
                                    "--vdp-selected-bg-color": "#c0d800",
                                    "--vdp-elem-font-size": "0.8em",
                                    "--vdp-elem-border-radius": "3px",
                                    "--vdp-divider-color": "#d5d9e0"
                                    }'
                            required />  
                            <div v-if="error.date" class="text-red-500 text-sm my-2" >{{ error.date }} </div>                       
                        </div>
                        <div class="mt-4">                                  
                            <label class="block" for="timebelt">Set timebelt </label>
                            <select v-model="timebelt" @change="clearTimebeltError" class="input bg-white" required >
                                <option value="" >Select timebelt</option>
                                <option v-for="timebelt in availableTimebelts.filter(item => item.name != 'Instant' )" :key="timebelt" :value="timebelt.id">{{ timebelt.name }} ( {{ timebelt.startTime }} - {{ timebelt.endTime }} ) </option>
                            </select>
                            <div v-if="error.timebelt" class="text-red-500 text-sm my-2" >{{ error.timebelt }} </div> 
                            <div v-if="notice" class="text-xs my-2 text-gray-500" >{{ notice }} </div>                                             
                        </div>
                    </form>
                    <div v-if="error.available" class="text-red-500 text-sm my-2" >{{ error.available }} </div>                       

                </div>
                <div class="mt-4" >
                    <div class="flex justify-end" >
                        <button type="submit" form="sort" class="bg-brand py-2 px-3 rounded text-black">
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
</template>

<script>
import moment from "moment";
import 'moment-timezone';
import Datepicker from 'vue3-datepicker'
import { ref } from 'vue'
const picked = ref(new Date());

export default {
        name:"Modal",
        components:{
            Datepicker
        },
        emits:['modalChanges'],
        props:['name','extras','amount','isInstant','timebelts','show','id','width','currentDate','type'],
        data(){
            return{
                Quantity: 1,
                showmodal : true,
                timebelt:"",
                date:"",
                todayDate:this.$store.getters.getDate,
                lowerDate:"",
                boundaryDate:"",
                extrasInfo:[],
                cart:[],
                error:{
                    date:"",
                    timebelt: "",
                    available:""
                },
                availableTimebelts:[],
                notice:"",
                arrange:false
            }
        },
        computed:{
          
        },
        methods:{
            getArrangeTimebelt(){
                let timebelt = this.availableTimebelts;
            },
            sortTimebelt(a,b){
                return this.global.convertTimebelt(a.startTime) - this.global.convertTimebelt(b.startTime)
            },
            setDate(){
                this.date = this.todayDate = picked ;
                let today = new Date(this.currentDate)
                let tomorrow = new Date(this.currentDate);
                tomorrow.setDate(today.getDate()+2);
                this.boundaryDate = tomorrow;
                this.lowerDate = today
            },
            sortMenu(){
                if (this.date && moment(new Date(this.date)).format('L') >= moment().format('L')) {
                    this.error.date = ""
                }else{
                    this.error.date = "You picked an invaild date"
                }
                //Checking timebelt
                if(this.timebelt){
                    this.error.timebelt = ""
                }else{
                   this.error.timebelt = "Select a time belt"
                }          
                //Checking if timebelt is window is still availble that day
                let selectedTimebelt = this.timebelts.find((item) => item.id == this.timebelt);
                let hourLimit = this.global.convertTimebelt(selectedTimebelt.endTime);
                let timebeltName = selectedTimebelt.name;
                let window = selectedTimebelt.deliveryWindow
                let restrictionTime = this.restrictionTime(hourLimit,window);
                if(moment(new Date(this.date)).format('L') == moment().format('L') && moment(this.currentDate).tz('Africa/Lagos').format("HH:mm:ss") > restrictionTime){
                    this.error.available = `${timebeltName} can only be booked for tomorrow`; 
                }else{
                    this.error.available = null;
                }
                this.timebelts.find((item)=> item.name == timebeltName)
                //Check if there is no error
                if (!this.error.timebelt && !this.error.date && !this.error.available) {
                    this.showmodal = false;
                    this.$store.dispatch('addDeliverydate',this.date);
                    this.$store.dispatch('addTimeBelt',JSON.stringify(selectedTimebelt));
                    this.$emit('modalChanges',JSON.stringify(selectedTimebelt));
                    if(this.$store.getters.getCart.cartItems.length > 0){
                        let Cart = this.$store.getters.getCart.cartItems;
                        if(Cart[0].timeBeltId != selectedTimebelt.id){
                            this.$store.dispatch('emptyCart');
                            this.$store.dispatch('addCountcart',0);
                        }
                    } 
                    
                }
            },
            restrictionTime(hours,mins){
                let restrictionTime = moment(`${hours}:00 :00`, 'HH:mm:ss');
                return moment(restrictionTime, "HH:mm:ss").subtract(mins, 'minutes').format("HH:mm:ss");
            },
            showAvailbleTimebelts(){
                this.availableTimebelts = this.timebelts.filter(item => (moment(this.todayDate).format('L')  == moment(this.currentDate).tz('Africa/Lagos').format('L') && moment(this.currentDate).tz('Africa/Lagos').format('HH:mm:ss') < this.restrictionTime(this.global.convertTimebelt(item.startTime),item.deliveryWindow)))

                this.availableTimebelts.sort(function(a, b) {
                    const changeToHour = (time) => { 
                        time.replace(/\s+/g);
                        let hours = time.match(/\d+/)[0];
                        let string = time.replace(/\d+/,'');
                        hours = (string.toLowerCase() == 'pm') ? ( parseInt(hours) == 12) ? hours : parseInt(hours) + 12 : hours
                        return hours 
                    }
                    let aStartTime = changeToHour(a.startTime);
                    let bStartTime = changeToHour(b.startTime);
                    return aStartTime - bStartTime;
                });
            },
            clearTimebeltError(){
               this.error.available  = null
                if(this.timebelt){
                   let selectedTimebelt = this.timebelts.find((item) => item.id == this.timebelt);
                   this.notice = `Note: Your meal will be delivered not later than ${selectedTimebelt.endTime} on ${this.shortenTime(this.date)}`
                }else{
                   this.notice =""
                }
            },
            shortenTime(date){
                return moment(date).format("Do MMM, YYYY"); 
            },
            changeAvailableTimebelt(){
                this.clearTimebeltError();
                if(moment(new Date(this.date)).format('L') > moment(this.currentDate).tz('Africa/Lagos').format('L')){
                    this.availableTimebelts = this.timebelts;
                }else{
                    this.showAvailbleTimebelts();
                }
            },
            showOnLoadTimebelts(){
                this.clearTimebeltError();
                if(moment(new Date(this.date)).format('L') > moment(this.currentDate).tz('Africa/Lagos').format('L')){
                    this.availableTimebelts = this.timebelts;
                }else{
                    this.availableTimebelts = this.timebelts.filter(item => (moment(this.todayDate).format('L')  == moment(this.currentDate).tz('Africa/Lagos').format('L') && moment(this.currentDate).tz('Africa/Lagos').format('HH:mm:ss') < this.restrictionTime(this.global.convertTimebelt(item.startTime),item.deliveryWindow)))
                    this.availableTimebelts.sort(function(a, b) {
                        const changeToHour = (time) => { 
                            time.replace(/\s+/g);
                            let hours = time.match(/\d+/)[0];
                            let string = time.replace(/\d+/,'');
                            hours = (string.toLowerCase() == 'pm') ? ( parseInt(hours) == 12) ? hours : parseInt(hours) + 12 : hours
                            return hours 
                        }
                        let aStartTime = changeToHour(a.startTime);
                        let bStartTime = changeToHour(b.startTime);
                        return aStartTime - bStartTime;
                    });
                    if(!this.availableTimebelts.length){
                        this.todayDate = picked ;
                        let today = new Date(this.currentDate)
                        let tomorrow = new Date(this.currentDate);
                        this.todayDate = new Date(tomorrow.setDate(today.getDate()+1));
                        this.availableTimebelts = this.timebelts;
                    }
                }
            }
        },
        created(){
            if(!this.show){
                this.setDate();
                this.showOnLoadTimebelts(); 
            }    
        },
}
</script>
