<template>
  <div class="font-mont text-sm md:text-base">
    <router-view/>
  </div>
  <Modal v-if="expired" :show="true"> 
    <h1 class="text-xl font-semibold">Expired Cart</h1>
    <p class="mt-4 text-sm" >Your delivery time has lapsed and item(s) in your cart will be cleared</p>
    <div class="flex space-x-4 mt-2 justify-end">
        <button @click="clearCart" class="bg-brand text-sm py-2 px-3 rounded-sm" >Continue</button>
    </div>
</Modal>
</template>
<script>
import Modal from "@/components/Modal.vue";
import moment from "moment";
export default {
  name:"App",
  data(){
    return {
       expired :false
    }
  },
  components:{
    Modal
  },
  methods:{
      async CheckCart(cart){
          let firstItem = (cart.cartItems.length) ?  cart.cartItems[0] : cart.containers[0].cartItems[0];
          if(this.moment().format('L') > this.moment(firstItem.expectedDeliveryDate).format('L')){
              this.expired = true;
          }else if(this.moment().format('L') == this.moment(firstItem.expectedDeliveryDate).format('L')){
              let url = this.$store.state.base_url + 'time-belt/' + firstItem.timeBeltId ;
              try {
                  let token = await this.$store.getters.getToken
                  let res =  await this.axios.get(url,{
                      headers: {
                          Authorization: 'Bearer ' + token
                      }
                  })  
                  let hourLimit = this.global.convertTimebelt(res.data.endTime);  
                  if(this.moment().hour() >= hourLimit){
                      this.expired = true;
                  }
              } catch (error) {
                  console.log(error);
              }
          }
      },
      clearCart(){
          this.expired = false;
          this.$store.dispatch('emptyCart');
          this.$store.dispatch('addCountcart',0);
      },
  },
  async mounted(){
    this.$store.dispatch('emptyCart')
    if(this.$store.getters.getUser){
      let user = this.$store.getters.getUser
      if(moment().format() > moment(user.refreshToken.expiryDate).format()){
        this.$store.dispatch('removeUserToken')
        return this.$router.push({ name: 'CustomerSignIn' });
      }
      this.global.getProfile()
      let cart = await this.global.unstructedCart()
      if(cart.cartItems.length || cart.containers.length){
        this.CheckCart(cart)
      }
    }
  }
}
</script>

<style>
#nav a.router-link-exact-active {
  color: #42b983;
}
.bg-overlay{
    background: rgba(0, 0, 0, 0.5);
}
button{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}
button,
a{
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 -webkit-tap-highlight-color: transparent;
}
</style>
