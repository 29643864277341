import axios from "./axios"
import store from "./store"

const saveCart =  async () =>{
    let url = store.state.base_url + 'cart'
    let data = cartDetails()
    let token = await store.getters.getToken
    await axios.put(url,data,{
        headers:{
            Authorization: 'Bearer ' + token 
        }
    })
}

const cartDetails = () =>{
    let foodCartItem = [];
    let comboCost = 0;
    let cart = store.getters.getCart
    store.getters.getCart.cartItems.forEach((item) => {
        let arrItem = {
            "foodId": item.foodId,
            "foodImage": item.foodImage,
            "foodName": item.foodName,
            "foodType": item.foodType,
            "price": item.amount,
            "quantity": item.quantity
        }
        foodCartItem.push(arrItem)
        comboCost = comboCost + (item.amount * item.quantity)
    });

    let foodContainerCartItems = [];
    let foodContainerCartItemsCost = 0;
    store.getters.getCart.containers.forEach((item) => {

        let foodCartItems = [];
        let foodCartItemsCost = 0
        item.cartItems.forEach((item) =>{
            let data = {
                "foodId": item.foodId,
                "foodImage": item.foodImage,
                "foodName":  item.foodName,
                "foodType": item.foodType,
                "price": item.amount,
                "quantity": item.quantity
            }
            foodCartItems.push(data)
            foodCartItemsCost = foodCartItemsCost + (item.quantity * item.amount)
        })

        let data = {
            "containerPrice": item.container.price,
            "contentPrice": (foodCartItemsCost * item.quantity),
            "foodCartItems": foodCartItems,
            "foodContainerId": item.container.id,
            "foodContainerImage":item.container.images[0],
            "foodContainerName": item.container.name,
            "quantity": item.quantity,
            "totalPrice": ((foodCartItemsCost + item.container.price) * item.quantity)
        }

        foodContainerCartItems.push(data)
        foodContainerCartItemsCost = foodContainerCartItemsCost + ((foodCartItemsCost + item.container.price) * item.quantity)
    })


    let data = {
        "deliveryAddress": store.getters.getAddress.address,
        "expectedDeliveryDate": (cart.cartItems.length) ? cart.cartItems[0].expectedDeliveryDate : cart.containers[0].cartItems[0].expectedDeliveryDate ,
        "foodCartItems": foodCartItem,
        "foodContainerCartItems": foodContainerCartItems,
        "foodImage": "string",
        "price": foodContainerCartItemsCost + comboCost,
        "restaurantId": (cart.cartItems.length) ? cart.cartItems[0].restaurantId : cart.containers[0].cartItems[0].restaurantId ,
        "timeBeltId": (cart.cartItems.length) ? cart.cartItems[0].timeBeltId : cart.containers[0].cartItems[0].timeBeltId
    }

    return data;

}

const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const unstructedCart = async () => {
    let url = store.state.base_url + 'cart'
    let token = await store.getters.getToken
    let res = await axios.get(url,{
        headers:{
            Authorization: 'Bearer ' + token
        }
    })
    let cart = res.data;
    let updateCart = convertCart(cart)
    store.dispatch('updateCart',updateCart)
    if(cart.restaurantId){
        url = store.state.base_url + 'restaurant/' + cart.restaurantId
        let response  =  await axios.get(url)
        let restaurant = response.data;
        store.dispatch('addResturantName',restaurant.publicURL)
    }

    return updateCart;
}

const getCart = async () => {
    let url = store.state.base_url + 'cart'
    let token = await store.getters.getToken
    let res = await axios.get(url,{
        headers:{
            Authorization: 'Bearer ' + token
        }
    })
    let cart = res.data;

    return cart;
}

const convertCart = (cart) => {
    let containers = [];
    if(cart.foodContainerCartItems){
        cart.foodContainerCartItems.forEach((item)=>{

            let cartItems = [];
            if(item.foodCartItems){
                item.foodCartItems.forEach((item)=>{
                    let cartDetails = {
                        "foodId": item.foodId,
                        "foodName": item.foodName,
                        "quantity": item.quantity,
                        "amount": item.price/item.quantity,
                        "foodImage": item.foodImage,
                        "timeBeltId": cart.timeBeltId,
                        "expectedDeliveryDate": cart.expectedDeliveryDate,
                        "restaurantId": cart.restaurantId,
                        "deliveryAddress": cart.deliveryAddress,
                        "foodType": item.foodType
                    }
                    cartItems.push(cartDetails);
                })
            }


            let containerItem = {
                "container": {
                    "id": item.foodContainerId,
                    "name": item.foodContainerName,
                    "restaurantId": cart.restaurantId,
                    "price": item.containerPrice,
                    "images": [
                        item.foodContainerImage
                    ],
                    "available": true
                },
                "cartItems": cartItems,
                "quantity": item.quantity
            }

            containers.push(containerItem);
        })
    }

    let comboCartItems = [];
    if(cart.foodCartItems){
        cart.foodCartItems.forEach((item) => {
            if(item.price){
                let cartDetails = {
                    "foodId": item.foodId,
                    "foodName": item.foodName,
                    "quantity": item.quantity,
                    "amount": item.price/item.quantity,
                    "foodImage": item.foodImage,
                    "timeBeltId": cart.timeBeltId,
                    "expectedDeliveryDate": cart.expectedDeliveryDate,
                    "restaurantId": cart.restaurantId,
                    "deliveryAddress": cart.deliveryAddress,
                    "foodType": item.foodType
                }
                comboCartItems.push(cartDetails);
            }
        });
    }

    let updateCart ={
        "containers": containers,
        "cartItems": comboCartItems
    }

    return updateCart
}
const emptyCart =  async() => {
    let url = store.state.base_url + 'cart';
    let token = await store.getters.getToken
    await axios.delete(url,{
        headers:{
            Authorization: 'Bearer ' +  token
        }
    })

}

const getProfile = async ()=>{

    let url = store.state.base_url + 'user-profile'; 
    let token = await store.getters.getToken
    let res = await axios.get(url,{
        headers: {
            Authorization: 'Bearer ' +  token
        }
    })

    store.dispatch('addUserProfile',res.data )
}

const getCartCost = (cart) => {
    let item = cart;
    let subTotalCost = 0;
    for (let i = 0; i < item.cartItems.length; i++) {
        const element = item.cartItems[i];
        let cost = element.quantity * element.amount;
        subTotalCost = subTotalCost + cost
    }
    for (let i = 0; i < item.containers.length; i++) {
        const element = item.containers[i].cartItems;
        let containerCost = 0;
        for(let i = 0; i < element.length; i++) {
            const item = element[i];
            let costItem = item.quantity * item.amount;
            containerCost = containerCost + costItem
        }
        subTotalCost = subTotalCost + ((containerCost + item.containers[i].container.price) * item.containers[i].quantity)  
    }
    return subTotalCost;
}
const bookOrder = async () => { 
    let cart  = store.getters.getCart
    let totalCost = getCartCost(cart)
    let latitude = store.getters.getAddress.latitude;
    let longitude = store.getters.getAddress.longitude;
    let url = store.getters.getBaseUrl + 'order/checkout';
    let data = {
        "deliveryAddress": store.getters.getAddress.address,
        "deliveryAddressLatitude": latitude,
        "expectedDeliveryDate": (cart.cartItems.length) ? cart.cartItems[0].expectedDeliveryDate : cart.containers[0].cartItems[0].expectedDeliveryDate ,
        "deliveryAddressLongitude": longitude,
        "restaurantId": (cart.cartItems.length) ? cart.cartItems[0].restaurantId : cart.containers[0].cartItems[0].restaurantId,
        "subTotalCost": totalCost,
        "totalCost": totalCost,
    }
    let token = await store.getters.getToken
    let res =  await axios.post(url,data,{
        headers:{
            Authorization: 'Bearer ' + token
        }
    })

    return res
}

let convertTimebelt = (time) => {
time.replace(/\s+/g);
let hours = time.match(/\d+/)[0]
let string = time.replace(/\d+/,'');
hours = (string.toLowerCase() == 'pm') ? ( parseInt(hours) == 12) ? hours : parseInt(hours) + 12 : hours
return hours
}

export default {
    formatNumber,
    saveCart,
    cartDetails,
    unstructedCart,
    emptyCart,
    getProfile,
    getCartCost,
    convertCart,
    bookOrder,
    convertTimebelt,
    getCart
}