import axios from "axios";
import store from '../store';
import router from '../router';
import moment from "moment";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

axios.interceptors.request.use(async (config) => {

  let user = store.state.user

  if(!user.accessToken){
    return config;
  }
 

  //Refresh token has expired
  if(moment().format() > moment(user.refreshToken.expiryDate).format()){
    store.dispatch('removeUserToken')
    return router.push({ name: 'Login' });
  }

  return config;
  
});

axios.interceptors.response.use(function (config) {
    return config;
  }, function (error) {
    if(error.response.status == 403){
        store.dispatch('removeUserToken')
        router.push({ name: 'CustomerSignIn' });
    }
    // Do something with request error
    return Promise.reject(error);
});

export default axios