import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';
import middleware from './middleware';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/NewHomepage.vue')
  },
  {
    path: '/waiting-list',
    name: 'Waiting',
    component: () => import(/* webpackChunkName: "about" */ '../views/Waiting.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/faqs',
    name: 'FAQS',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQS.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/restaurants',
    name: 'Resturants',
    component: () => import('../views/Resturants.vue')
  },
  {
    path: '/restaurant/:publicUrl',
    name: 'ResturantsPage',
    component: () => import('../views/ResturantsPageNew.vue'),
    meta: { transition: 'slide-left' },
  },
   {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('../views/Checkout.vue'),
    beforeEnter: [middleware.customerToken]
  },
  {
    path: '/payment-information',
    name: 'PaymentSuccess',
    component: () => import('../views/PaymentSuccess.vue'),
    beforeEnter: [middleware.OrderData]
  }, 
  {
    path: '/register',
    name: 'CustomerSignUp',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/Register.vue'),
    beforeEnter: [middleware.redirectHome]
  },
  {
    path: '/login',
    name: 'CustomerSignIn',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/Login.vue'),
    beforeEnter: [middleware.redirectHome]
  },
  {
    path: '/forgot-password',
    name: 'CustomerForgotPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/password/ForgotPassword.vue'),
    beforeEnter: [middleware.redirectHome]
  },
  {
    path: '/verify-email',
    name: 'CustomerVerifyEmail',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/password/VerifyEmail.vue'),
    beforeEnter: [middleware.redirectHome]
  },
  {
    path: '/email-confirmation',
    name: 'CustomerConfirmEmail',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/password/EmailConfirmation.vue'),
    beforeEnter: [middleware.redirectHome]
  },
  {
    path: '/order/:order/rating/:star',
    name: 'Rating',
    component: () => import(/* webpackChunkName: "about" */ '../views/Rating.vue')
  },
  {
    path: '/reset-password',
    name: 'CustomerChangePassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/password/ChangePassword.vue'),
    beforeEnter: [middleware.redirectHome]
  },
  {
    path: '/customer/order-history',
    name: 'OrderHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/OrderHistory.vue'),
    beforeEnter: [middleware.CheckToken]
  },
  {
    path: '/customer/settings',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/Settings.vue'),
    beforeEnter: [middleware.CheckToken]
  },
  {
    path: '/customer/wallet-history',
    name: 'WalletHistory',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/Wallet.vue'),
    beforeEnter: [middleware.CheckToken]
  },
  {
    path: '/customer/referral',
    name: 'Referral',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/Referral.vue'),
    beforeEnter: [middleware.CheckToken]
  },
  {
    path: '/customer/reviews',
    name: 'Reviews',
    component: () => import(/* webpackChunkName: "about" */ '../views/customer/Reviews.vue'),
    beforeEnter: [middleware.CheckToken]
  },
  {
    path: '/not-allowed',
    name: 'NotAllowed',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotAllowed.vue')
  },
  { path: '/:catchAll(.*)', redirect: '/404' },
  {
    path: '/404',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
      return { top: 0 }
  },
})

router.beforeEach((to, from) => {
  if (to.name === "Resturants") {
    const Address = store.getters.getAddress
    if (!Address) {
      store.dispatch('errAddress', 'Please enter your delivery address');
      router.push({ name: 'Home' });
    } else if (!Address.latitude || !Address.longitude){
      store.dispatch('errAddress', 'Invaild delivery address');
      router.push({ name: 'Home' });
    }
  }
})

export default router
