import store from '../store';
import router from './index'

    const CheckToken = (to) => {
      if (!store.getters.getToken) {
        router.push({ name: 'CustomerSignIn' });
      }
    }

    const customerToken = async (to) => {
        if(!store.getters.getUser) {
          router.push({ name: 'CustomerSignIn', query: { from: 'checkout' } });
        }
    }

    const OrderData = (to) => {
        if (store.getters.getOrder == "") {
           router.push('/')
        }
      }

    const deleteToken = (to) => {
        store.dispatch('removeUserToken')
        router.push({ name: 'CustomerSignIn' });
       
    }
     
    const redirectHome = (to) => {
        if(store.getters.getUser){
          router.push({ name: 'Home'});
        }
    }
export default{
    CheckToken,
    customerToken,
    OrderData,
    deleteToken,
    redirectHome
}