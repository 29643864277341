import { createStore } from 'vuex'
var CryptoJS = require("crypto-js");
import global from '../function'
import router from '../router';
import moment from 'moment';
import axios from "../axios"

export default createStore({
  state: {
    Address: {},
    base_url: process.env.VUE_APP_BASE_URL,
    dellyman_base_url: 'https://dellyman.com/api/v3.0/',
    cart: {
      containers: [],
      cartItems: []
    },
    revertedCart: {
    },
    orderCart: {},
    navigatorError: "",
    errAddress: "",
    order: "",
    orderSummary: "",
    deliverydate: "",
    timebelt: "not availble",
    countCart: 0,
    OrderStoreName: "",
    user: "",
    newChange: false,
    mealInstruction:'',
    userProfile: "",
    delievryStatus: [
      { name: "Ready for Pickup", status: "ORDER_READY_FOR_PICKUP" },
      { name: "Order Shipped", status: "ORDER_SHIPPED" },
      { name: "Order Delivered", status: "ORDER_DELIVERED" },
      { name: "Order Cancelled", status: "ORDER_CANCELLED" },
      { name: "Order Refunded", status: "ORDER_REFUNDED" },
    ],
    storeDetails: {},
    isUserLoggedin: false
  },
  getters: {
    getAddress(state) {
      let deliveryAddress = localStorage.getItem('noshtribe_address') ? JSON.parse(localStorage.getItem('noshtribe_address')) : [];
      if (typeof deliveryAddress !== 'undefined') {
        state.Address = deliveryAddress
      }
      return state.Address
    },
    getNewChange(state) {
      return state.newChange
    },
    getTimebelt(state) {
      let timebelt = sessionStorage.getItem('nushtribe_timebelt') ? JSON.parse(sessionStorage.getItem('nushtribe_timebelt')) : [];
      if (typeof timebelt !== 'undefined') {
        state.timebelt = JSON.parse(sessionStorage.getItem('nushtribe_timebelt'));
      }
      return state.timebelt
    },
    getUserProfile(state) {
      return state.userProfile
    },
    async getToken(state) {
      let token = localStorage.getItem('user') ? localStorage.getItem('user') : null;
      if (token) {
        var bytes = CryptoJS.AES.decrypt(token, process.env.VUE_APP_SECRET_PHRASE);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        state.user = decryptedData;
        if (moment().format() > moment(state.user.accessToken.expiryDate).format()) {
          try {
            let response = await axios.post('/auth/token-refresh', {}, {
              headers: {
                'Refresh-Token': state.user.refreshToken.token
              }
            });
            state.user.accessToken = response.data
            var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(state.user), process.env.VUE_APP_SECRET_PHRASE).toString();
            localStorage.setItem("user", ciphertext)

            return state.user.accessToken.token
          } catch (error) {
            console.log(error)
          }
        }
        return state.user.accessToken.token
      } else {
        state.user = ""
        return;
      }
    },
    getUser(state) {
      let token = localStorage.getItem('user') ? localStorage.getItem('user') : null;
      if (token) {
        var bytes = CryptoJS.AES.decrypt(token, process.env.VUE_APP_SECRET_PHRASE);
        var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return state.user = decryptedData;
      }
      state.user = ""
      return state.user
    },
    getDeliverydate(state) {
      return state.deliverydate
    },
    getDate(state) {
      const date = sessionStorage.getItem('nushtribe_delivery_date') ? sessionStorage.getItem('nushtribe_delivery_date') : '';
      if (date) {
        state.deliverydate = date;
      }
      return state.deliverydate
    },
    getBaseUrl(state) {
      return state.base_url
    },
    getCart(state) {
      if (state.cart.cartItems.length || state.cart.containers.length) {
        return state.cart
      }
      const cartDetails = JSON.parse(localStorage.getItem('nushtribe_cart'));
      if (cartDetails) {
        state.cart = cartDetails;
      }
      return state.cart
    },
    clearCoutCart() {
      if (state.cart.cartItems.length || state.cart.containers.length) {
        state.countCart = 0
      }
    },
    getcountCart(state) {
      if (state.cart.cartItems.length || state.cart.containers.length) {
        state.countCart = state.cart.cartItems.length + state.cart.containers.length
        return state.countCart
      }
      return state.countCart
    },
    getOrderStoreName(state) {
      const StoreName = sessionStorage.getItem('nushtribe_resturant_name') ? sessionStorage.getItem('nushtribe_resturant_name') : '';
      if (StoreName) {
        state.OrderStoreName = StoreName;
      }
      return state.OrderStoreName
    },
    getnavigatorError() {
      return state.navigatorError
    },
    getMealInstruction(state) {
      return state.mealInstruction
    },
    getOrder(state) {
      const orderDetails = sessionStorage.getItem('noshtribe_order_created') ? JSON.parse(sessionStorage.getItem('noshtribe_order_created')) : "";
      if (typeof orderDetails !== 'undefined') {
        state.order = orderDetails;
      }
      return state.order
    },
    getCost(state) {
      return state.orderSummary
    },
    getisUserLoggedin(state) {
      return state.isUserLoggedin
    }

  },
  mutations: {
    addCart(state, addCart) {
      state.cart = addCart;
      localStorage.setItem('nushtribe_cart', JSON.stringify(addCart));
      if (state.user) {
        global.saveCart()
      }
    },
    updateCart(state, cart) {
      state.cart = cart;
      localStorage.setItem('nushtribe_cart', JSON.stringify(cart));
    },
    addResturantName(state, name) {
      state.OrderStoreName = name;
      sessionStorage.setItem('nushtribe_resturant_name', name);
    },
    errAddress(state, error) {
      state.errAddress = error
    },
    setMealInstruction(state, mealInfo) {
      state.mealInstruction = mealInfo
    },
    emptyCart(state) {
      state.cart = {
        containers: [],
        cartItems: []
      }
      localStorage.removeItem('nushtribe_cart');
      if (state.user) {
        global.emptyCart()
      }

    },
    addOrder(state, order) {
      state.order = order;
      sessionStorage.setItem('noshtribe_order_created', order);
    },
    addCost(state, cost) {
      state.orderSummary = cost;
    },
    addTimeBelt(state, timebelt) {
      sessionStorage.setItem('nushtribe_timebelt', timebelt);
      state.timebelt = timebelt
    },
    addDeliveryaddress(state, deliveryAddress) {
      state.Address = deliveryAddress;
      localStorage.setItem('noshtribe_address', deliveryAddress);
    },
    addDeliverydate(state, date) {
      sessionStorage.setItem('nushtribe_delivery_date', date);
      state.deliverydate = date
    },
    addUserToken(state, token) {
      localStorage.setItem('user', token);
      state.user = token
    },
    addUserProfile(state, profile) {
      state.userProfile = profile
    },
    emptyResurantName(state) {
      state.OrderStoreName = "";
      sessionStorage.removeItem('nushtribe_resturant_name');
    },
    removeOrder(state) {
      state.order = "";
      sessionStorage.removeItem('noshtribe_order_created');
    },
    removeUserToken(state) {
      state.user = "";
      localStorage.removeItem('user');
    },
    emptyTimebelt(state) {
      state.deliverydate = "";
      state.timebelt = "";
      sessionStorage.removeItem('nushtribe_timebelt');
      sessionStorage.removeItem('nushtribe_delivery_date');
    },
    addCountcart(state, count) {
      state.countCart = count
    },
    setNewChange(state, bool) {
      state.newChange = bool
    },
    saveRevertedCart(state, cart) {
      state.revertedCart = cart;
    },
    saveOrdercart(state, orderCart) {
      state.orderCart = orderCart
    },
    saveStoreDetail(state, storeDetails) {
      state.storeDetails = storeDetails
    },
    isUserLoggedin(state, boolean) {
      state.isUserLoggedin = boolean
    }
  },
  actions: {
    addDeliveryaddress(context, deliveryAddress) {
      context.commit('addDeliveryaddress', deliveryAddress)
    },
    addCart(context, addCart) {
      context.commit('addCart', addCart)
    },
    updateCart(context, cart) {
      context.commit('updateCart', cart)
    },
    errAddress(context, error) {
      context.commit('errAddress', error)
    },
    emptyCart(context) {
      context.commit('emptyCart')
    },
    addOrder(context, order) {
      context.commit('addOrder', order)
    },
    addCost(context, cost) {
      context.commit("addCost", cost)
    },
    addTimeBelt(context, timebelt) {
      context.commit('addTimeBelt', timebelt)
    },
    addDeliverydate(context, date) {
      context.commit('addDeliverydate', date)
    },
    addResturantName(context, name) {
      context.commit('addResturantName', name)
    },
    setMealInstruction(context, val){
      context.commit('setMealInstruction',val)
    },
    emptyResurantName(context) {
      context.commit('emptyResurantName')
    },
    emptyTimebelt(context) {
      context.commit('emptyTimebelt')
    },
    addCountcart(context, count) {
      context.commit('addCountcart', count)
    },
    addToken(context, token) {
      context.commit('addToken', token)
    },
    removeUserToken(context) {
      context.commit('removeUserToken');
    },
    addUserToken(context, token) {
      context.commit('addUserToken', token)
    },
    addUserProfile(context, profile) {
      context.commit('addUserProfile', profile)
    },
    removeOrder(context) {
      context.commit('removeOrder')
    },
    setNewChange(context, bool) {
      context.commit('setNewChange', bool)
    },
    saveRevertedCart(context, cart) {
      context.commit('saveRevertedCart', cart)
    },
    saveOrdercart(context, orderCart) {
      context.commit('saveOrdercart', orderCart)
    },
    saveStoreDetail(context, storeDetails) {
      context.commit('saveStoreDetail', storeDetails)
    },
    isUserLoggedin(context, boolean) {
      context.commit('isUserLoggedin', boolean)
    }
  },
  modules: {
  }
})
